import { NavLink } from "react-router-dom";
import * as React from "react";

const navLinkClassName =
  "bg-cornflower-blue-400 transition duration-200 delay-100 hover:bg-cornflower-blue-700 hover:delay-100";
const navLinkActiveClassName = "bg-cornflower-blue-700";

export function LeftNavLink(props: {
  to: string;
  label: string;
  icon: React.ReactNode;
  selected: boolean;
  enabled: boolean;
}) {
  const classes = props.selected ? navLinkActiveClassName : navLinkClassName;
  if (!props.enabled) return null;
  return (
    <NavLink to={props.to}>
      <div className="flex flex-col items-center justify-center">
        <div
          className={`z-99 h-11 w-11 rounded-lg flex-col items-center justify-center gap-1 mb-1 flex text-white ${classes}`}
        >
          {props.icon}
        </div>
        <div style={{ fontSize: 10 }}>{props.label}</div>
      </div>
    </NavLink>
  );
}

export function LeftNavButton(props: {
  children: React.ReactNode;
  icon: React.ReactNode;
}) {
  const classes = navLinkClassName;
  return (
    <div className="cursor-pointer flex flex-col items-center justify-center">
      <div
        className={`z-99 h-11 w-11 rounded-lg flex-col items-center justify-center gap-1 mb-1 flex text-white ${classes}`}
      >
        {props.icon}
      </div>
      <div style={{ fontSize: 10 }}>{props.children}</div>
    </div>
  );
}
