import React, { useEffect, useState } from "react";
import IconMessaging from "@/icons/IconMessaging";
import IconInsightsReports from "@/icons/IconInsightsReports";
import IconSettingsGear from "@/icons/IconSettingsGear";
import LogoWhite from "@velaro/velaro-shared/src/images/Logo_White.svg";
import IconNotificationsBell from "@/icons/IconNotificationsBell";
import { LeftNavButton, LeftNavLink } from "./LeftNavItems";
import useProfile from "@/hooks/useProfile";
import DesktopProfileMenu from "../DesktopProfileMenu";
import NotifyButton from "../../NotifyButton";
import useStatus from "@/hooks/useStatus";
import useTranslations from "@/hooks/useTranslations";
import { useLocation } from "react-router-dom";
import IconContacts from "@/icons/IconContacts";
const adminUrl = import.meta.env.VITE_ADMIN_URL as string;

export default function LeftNav() {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { profile } = useProfile();
  const translation = useTranslations();

  const lng = navigator.language;
  const { status, setStatus } = useStatus();
  const availabilityColor =
    status === "Available" ? "bg-success-500" : "bg-danger-500";
  const location = useLocation();
  return (
    <div className="w-24 h-screen px-4 py-4 bg-cornflower-blue-500 flex-col items-center justify-between inline-flex text-white">
      <img className="-mt-4 h-16 w-16 mb-8" src={LogoWhite} alt="Velaro Logo" />
      <div className="flex flex-col gap-4 grow justify-start">
        <LeftNavLink
          to="/conversations"
          label={translation.key("messaging")}
          selected={location.pathname === "/conversations"}
          icon={<IconMessaging />}
          enabled={true}
        />
        <LeftNavLink
          to="/dashboard"
          label={translation.key("insights")}
          selected={location.pathname === "/dashboard"}
          icon={<IconInsightsReports />}
          enabled={true}
        />
        <LeftNavLink
          to="/contacts"
          label={translation.key("contacts")}
          selected={location.pathname === "/contacts"}
          icon={<IconContacts />}
          enabled={true}
        />
      </div>
      <div className="flex flex-col gap-4 grow justify-end">
        <NotifyButton />
        {profile.roles && profile.roles.includes("Administrator") && (
          <a href={adminUrl} target="_blank" rel="noreferrer">
            <LeftNavButton icon={<IconSettingsGear />}>
              {translation.key("admin")}
            </LeftNavButton>
          </a>
        )}
        <div className="w-16 h-px relative bg-cornflower-blue-400 rounded-full" />
        <div className="relative">
          <button
            className="w-full flex items-center justify-center"
            onClick={(e) => {
              setShowProfileMenu(!showProfileMenu);
              e.stopPropagation();
            }}
          >
            <img
              className="h-12 w-12 rounded-full border"
              src={profile.picture}
              alt=""
            />
            <div
              className={`absolute w-4 h-4 rounded-full ${availabilityColor} right-2 bottom-[0px] border border-white`}
            ></div>
          </button>
          {showProfileMenu && (
            <DesktopProfileMenu onClose={() => setShowProfileMenu(false)} />
          )}
        </div>
      </div>
    </div>
  );
}
