import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import useRoleListItems from "@/hooks/useRolesListItems";
import useStatus from "@/hooks/useStatus";
import useTranslations from "@/hooks/useTranslations";
import IconArrowForward1 from "@/icons/IconArrowForward1";
import IconHelp from "@/icons/IconHelp";
import IconLogOut from "@/icons/IconLogOut";
import IconMoon from "@/icons/IconMoon";
import IconSettingsGear from "@/icons/IconSettingsGear";
import IconTinyCamera from "@/icons/IconTinyCamera";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OutlineBadge from "@velaro/velaro-shared/src/components/Badges/OutlineBadge";

interface Props {
  onClose(): void;
}

export default function DesktopProfileMenu(props: Props) {
  const { profile, setProfile } = useProfile();
  const [profilePicture, setProfilePicture] = useState<Blob>();
  const [pictureUrl, setPictureUrl] = useState("");
  const profilePictureInput = useRef<HTMLInputElement>(null);
  const [showStatusPanel, setShowStatusPanel] = useState(false);

  const api = useApi();
  const { status, setStatus } = useStatus();
  const { getRoleNameById } = useRoleListItems();
  const statusRef = useRef<HTMLDivElement>(null);
  const translation = useTranslations();

  useEffect(() => {
    setPictureUrl(profile.picture);
  }, [profile.picture]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        statusRef.current &&
        !statusRef.current.contains(event.target as Node)
      ) {
        props.onClose();
      }
    };

    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [props]);

  async function handleSubmit(picture: File) {
    const formData = new FormData();

    formData.append("name", profile.displayName);
    formData.append("phone", profile.phone);

    if (picture) {
      formData.append("picture", picture);
    }

    const resp = await api.messaging.postFormData("Profile/Update", formData);
    const data = await resp.json();

    setProfile(data);

    if (profilePictureInput.current) {
      profilePictureInput.current.value = "";
    }
  }

  function renderRoleBadge(role: string, index: number) {
    let roleName = role;
    const parsedNumber = parseInt(role);
    if (!isNaN(parsedNumber)) {
      roleName = getRoleNameById(parsedNumber);
    }

    return <OutlineBadge key={index} label={roleName} size={"sm"} />;
  }

  const statusColor = useMemo(() => {
    switch (status) {
      case "Available":
        return "success-500";
      case "Unavailable":
        return "danger-500";
    }
  }, [status]);

  return (
    <div className="absolute bottom-0 left-16 z-20 text-slate-gray-600 shadow border rounded bg-white w-64">
      <div className="relative px-4">
        <div className="relative flex justify-center py-2">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-full border"
              src={pictureUrl}
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="font-semibold truncate w-64 text-center">
            {profile.displayName}
          </div>
        </div>
        <div className="flex justify-center flex-wrap pb-2">
          {profile.roles?.map(renderRoleBadge)}
        </div>
        <div className="relative w-full">
          {showStatusPanel && (
            <div className="absolute w-40 bg-white rounded shadow top-0 left-[calc(100%+10px)]">
              <div
                className="flex items-center h-8 px-4 hover:bg-slate-gray-200 hover: cursor-pointer m-2 rounded"
                onClick={() => {
                  setStatus("Available");
                  setShowStatusPanel(false);
                }}
              >
                <div className={`w-4 h-4 rounded-full bg-success-500`}></div>
                <div className="text-sm ml-2">
                  {translation.key("available")}
                </div>
              </div>
              <div
                className="flex items-center h-8 px-4 hover:bg-slate-gray-200 hover: cursor-pointer m-2 rounded"
                onClick={() => {
                  setStatus("Unavailable");
                  setShowStatusPanel(false);
                }}
              >
                <div className={`w-4 h-4 rounded-full bg-danger-500`}></div>
                <div className="text-sm ml-2">
                  {translation.key("unavailable")}
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center pb-2">
            <div
              className="flex justify-center items-center rounded hover:bg-slate-gray-200 hover: cursor-pointer p-1"
              onClick={() => setShowStatusPanel(true)}
              ref={statusRef}
            >
              <div className={`w-4 h-4 rounded-full bg-${statusColor}`}></div>
              <div className="text-sm ml-2">
                {translation.key(status.toString().toLowerCase())}
              </div>
              <IconArrowForward1 className="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
      <hr className="my-2 border-gray-200" />
      <div className="whitespace-nowrap">
        <Link
          to="/profile"
          onClick={() => {
            props.onClose();
          }}
          className="flex items-center h-12 px-4 hover:bg-slate-gray-200 hover:text-cornflower-blue-500 m-2 rounded"
        >
          <div className="flex-none w-10">
            <IconSettingsGear className="h-6 w-6" />
          </div>
          <div className="flex-1">{translation.key("profile_settings")}</div>
          <div className="flex-none w-10 text-right">
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </Link>
        <a
          onClick={() => {
            props.onClose();
          }}
          href="https://help.velaro.com/"
          className="flex items-center h-12 px-4 hover:bg-slate-gray-200 hover:text-cornflower-blue-500 m-2 rounded"
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="flex-none w-10">
            <IconHelp className="h-6 w-6" />
          </div>
          <div className="flex-1">{translation.key("help")}</div>
        </a>
        {/* <div className="flex items-center h-12 px-4 hover:bg-slate-gray-200 hover:text-cornflower-blue-500 m-2 rounded">
          <div className="flex-none w-10">
            <IconMoon className="h-6 w-6" />
          </div>
          <div className="flex-1">Dark mode theme</div>
        </div> */}
        <hr className="my-2 border-gray-200" />
        <Link
          to="/logout"
          className="flex items-center h-12 px-4 hover:bg-slate-gray-200 hover:text-cornflower-blue-500 m-2 rounded"
        >
          <div className="flex-none w-10">
            <IconLogOut className="h-6 w-6" />
          </div>
          <div className="flex-1">{translation.key("log_out")}</div>
        </Link>
      </div>
    </div>
  );
}
